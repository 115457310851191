import React, {useEffect, useState} from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import {makeStyles, useTheme, Typography} from '@material-ui/core';
import { makeValidation, validateForm, isValidForm } from '../../global/formHelper';

import Images from '../../assets/imgs/index';
import InputField from '../../components/inputField';
import ProgressButton from '../../components/progressButton';
import Alert from '@material-ui/lab/Alert';
import API from '../../global/api';

import background from "../../assets/imgs/Login_Screen.png";
import { useParams } from 'react-router';

const useStyles = makeStyles(theme => ({
  signinContainer: {
    width: '100%',
    maxWidth: 360,
    margin: 'auto',
    overflow: 'auto',
    padding: 20
  },
  form: {
    marginBottom: 10
  },
  footer: {
    marginTop: 40
  },
  logo: {
    width: '80%',
    height: 'auto',
    marginBottom: 20
  },
  verifyingLoader: {
    width: '80%',
    maxWidth: 600,
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))


const ResetPassword = props => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const {t}  = useTranslation();

  const {token} = useParams();

  const [form, setForm] = useState({
    token: token,
    new_password: '',
    confirm_password: ''
  })

  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const [validation, setValidation] = useState(makeValidation([
    {
      name: 'new_password',
      display_name: 'New Password',
      required: true,
      additional: [
        {
          type: 'password',
          error: 'Password must be at least 8 characters and include letters and numbers'
        }
      ]
    },
    {
      name: 'confirm_password',
      display_name: 'Confirm Password',
      required: true,
      additional: [
        {
          type: 'match',
          field: 'new_password',
          error: 'Your passwords do not match'
        }
      ]
    }
  ]));

  useEffect(() => {
    const newValidation = validateForm(form, validation);
    setValidation(newValidation);
  }, [form])


  const handleInputChange = e => {
    let newForm = {...form};
    newForm[e.target.name] = e.target.value;
    setForm(newForm);
  }

  const handleSubmit = () => {
    setError(null);
    setSuccess(false);

    const valid = isValidForm(form, validation);
    setValidation(valid.validation);
    if(valid.isValid){

      setWaiting(true);
      API.req(`/user/password/reset`, 'POST', form).then(
        res => {
          setWaiting(false);
          if(res.success === true){
            setSuccess(true);
            setForm({
              token: token,
              new_password: '',
              confirm_password: ''
            })
            setTimeout(() => {
              props.history.push('/');
            }, 3000)
          } else
            setError(res.msg);
        },
        err => {
          setWaiting(false);
          setError(err)
        }
      )

    }
  }


  return (
    
    <div className="container" style={{backgroundImage: `url(${background})` }}>

      <div className="inner">

        <div className={classes.signinContainer}>
          
          <div style={{flex: 1}}></div>
          
          {/* Sign in form */}
          <div style={{paddingTop: 20, paddingBottom: 20}}>

            <div className="flex-col align-center">
              <img src={Images.logo_with_sub} className={classes.logo} alt="BerthVend Smart Solutions" />
            </div>

            <div>

              <Typography variant="h4" align="center" gutterBottom={true}>{t('RESET_PASSWORD')}</Typography>

              {error && <Alert severity='error'>{error}</Alert>}
              {success && <Alert severity='success'>{t('RESET_PASSWORD_SUCCESS')}</Alert>}

              <div className={classes.form}>
                <InputField type="password" name='new_password' size='large' validation={validation.new_password} value={form.new_password} handleChange={handleInputChange} />
                <InputField type="password" name='confirm_password' size='large' validation={validation.confirm_password} value={form.confirm_password} handleChange={handleInputChange} />
                <ProgressButton variant="contained" size="large" color="primary" fullWidth waiting={waiting} onClick={handleSubmit} gutterBottom={true}>Change Password</ProgressButton>
              </div>

            </div>
          </div>

          <div style={{flex: 1}}></div>
        </div>

      </div>

    </div>
  );
}

export default ResetPassword;
